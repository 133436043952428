import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby"
import TeamCard from "../TeamCard/TeamCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/styles/_index.scss';

const MoreTeamDetails = ({teamsData}) => {

    const data = useStaticQuery(graphql`
        query GetAllTeams {
            glstrapi {
                teams(limit:7) {
                    id
                    name
                    email
                    designation
                    mobile_no
                    slug
                    ggfx_results {
                        transforms
                        src_cftle
                    }
                    tile_image {
                        url
                        url_sharp {
                            id
                            childImageSharp {
                              gatsbyImageData(
                                formats: WEBP
                                height: 240
                                width: 208
                                layout: FIXED
                                quality: 90
                                transformOptions: { cropFocus: NORTH, fit: COVER }
                              )
                            }
                        }
                    }
                }
            }
        }
    `)

    const allTeams = teamsData ? teamsData : data.glstrapi.teams

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <div className="team-details-more-wrapper">
            <Container>
                <Row>
                    <Col>
                    <h2>More of the team</h2>
                    </Col>
                </Row>
            <Row>
                <Col>
                    <Slider className="about-team-slider" {...settings}>
                        {
                            allTeams.map((data, i) => {

                                return (
                                    <TeamCard team={data} normalImg="normal" />
                                )
                            })
                        }
                    </Slider>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default MoreTeamDetails